import { observable, action } from 'mobx';

export default class SiteStore {
  @observable host = window.location.hostname || '';

  @observable isOrder = false;

  @observable name = '';

  @observable siteParams = '';

  @observable hostSet = new Set([
    'admin.test.datasmarker.com',
    'admin.datasmarker.com',
    'label.test.datasmarker.com',
    'label.datasmarker.com',
    'client.test.datasmarker.com',
    'client.datasmarker.com',
    'label-datasmarker.facethink.com',
    'admin-pre.datasmarker.com',
    'label-pre.datasmarker.com',
    'client-pre.datasmarker.com',
    // '127.0.0.1'
  ]);

  @action
  updateHost(): void {
    this.host = window.location.hostname;
    this.updateIsOrder();
    this.updateSiteName();
    this.updateSiteParams();
    const headDom = document.getElementsByTagName('head')[0];
    let faviconLink = document.querySelector('link[rel="icon"]');
    let configLink = document.querySelector('link[rel="manifest"]');
    if (configLink === null) {
      configLink = document.createElement('link');
      headDom.appendChild(configLink);
    }
    if (faviconLink === null) {
      faviconLink = document.createElement('link');
      headDom.appendChild(faviconLink);
    }
    const titleDom = document.createElement('title');
    titleDom.innerText = this.name;
    if (!this.hostSet.has(this.host)) {
      faviconLink.setAttribute('href', '/vegas-favicon.ico');
      configLink.setAttribute('href', '/manifest.json');
    } else {
      faviconLink.setAttribute('href', '');
    }
    headDom.appendChild(titleDom);
  }

  @action
  updateIsOrder(): void {
    this.isOrder = this.hostSet.has(this.host);
  }

  @action
  updateSiteName(): void {
    this.name = this.hostSet.has(this.host) ? 'Datas Marker' : 'Vegas';
  }

  @action
  updateSiteParams(): void {
    this.siteParams = this.hostSet.has(this.host) ? 'datasMarker' : 'vegas';
  }
}
